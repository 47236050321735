import { useAppSelector } from '@/redux/hooks';
import { Box, Button, IconButton, Typography } from '@mui/material';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import React, { useEffect, useMemo, useState } from 'react';
import { useMain } from '@/contexts/main';
import ModalWaiter from '@/components/ModalWaiter';
import ModalInfo from '@/components/ModalInfo';
import { useRouter } from 'next/router';
import ModalFilter from '@/components/ModalFilter';
import FixedDepartmentsDelivery from '@/pages/pedidos/components/FixedDepartments';
import { p400, p600 } from '@/styles/typography/fonts';
import { IoIosArrowBack } from 'react-icons/io';
import { LuHandPlatter } from 'react-icons/lu';
import { isMobile } from 'react-device-detect';
import CardapioTranslationOptions from '@/layout/topAppBar/components/cardapioTranslationOptions';
import IconRatingComponent from '@/layout/topAppBar/components/iconRating';
import RatingScreenCardapio from '@/layout/topAppBar/components/ratingScreenCardapio';

export default function HomeDelivery() {
	const router = useRouter();
	const defaultRoute = router.route;
	const isPedidos = defaultRoute === '/pedidos';
	const { storageLogin } = useMain();
	const home = useAppSelector(state => state.home);
	const { responseLogin, client, loginFields } = useAppSelector(state => state.auth);
	const { list } = useAppSelector(state => state.home);
	const authDelivery = useAppSelector(state => state.authDelivery);
	const authNormal = useAppSelector(state => state.auth);
	const auth = isPedidos ? authDelivery : authNormal;
	const personalData = responseLogin || storageLogin;
	const [openWaiter, setOpenWaiter] = useState(false);
	const [showSnack, setShowSnack] = useState(false);
	const [snackMsg, setSnackMsg] = useState('');
	const [openFilter, setOpenFilter] = useState(false);
	const [value, setValue] = useState<number | undefined>(undefined);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [ratingScreenOpen, setRatingScreenOpen] = useState(false);
	const showWaiter = useMemo(
		() => !!(auth.client?.config_cliente?.chamada_garcom && loginFields && !loginFields?.config_qrcode?.pre_pago && personalData && isMobile),
		[auth.client?.config_cliente?.chamada_garcom, loginFields, loginFields?.config_qrcode?.pre_pago, personalData],
	);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const handleScroll = () => {
		const position = window.pageYOffset;
		const element = document.querySelectorAll('.productContainer');
		let currentId;

		element.forEach(el => {
			const id_depto = el.getAttribute('id');

			if (id_depto) {
				const elemento_id = document.getElementById(id_depto?.toString());

				if (elemento_id) {
					const offset = elemento_id.getBoundingClientRect().top;

					if (offset < 120) {
						currentId = id_depto;
					}
				}
			}
		});

		if (currentId) {
			setValue(+currentId);
		}

		setScrollPosition(position);
	};

	const scrollDown = (id: number) => {
		const element = document.getElementById(id.toString());

		if (element !== null) {
			let pos = element.offsetTop - 80;

			window.scrollTo({ top: pos, behavior: 'smooth' });
		}
	};

	function handleCloseWaiter() {
		setOpenWaiter(false);
	}

	function handleModal(state: boolean) {
		setShowSnack(state);
	}

	useEffect(() => {
		if (home.responseWaiter) {
			if (home.responseWaiter.mensagem) {
				setSnackMsg(home.responseWaiter.mensagem);
				setShowSnack(true);
			}

			if (home.responseWaiter.retorno) {
				handleCloseWaiter();
				setSnackMsg('Garçom chamado, por favor aguarde!');
				setShowSnack(true);
			}
		}
	}, [home.responseWaiter]);

	return (
		<Box className="appBarLayout">
			<Box sx={{ flexGrow: 1 }} id="appBar">
				<Box className="boxAppBar">
					<Box>
						{(list?.tipo === 'M' || list?.conteudo) && (
							<div className="headerBottomSheet">
								<Button variant="contained" className="btn" style={{ backgroundColor: '#' + client?.config_cliente?.cor }} onClick={() => router.push('/')}>
									<IoIosArrowBack style={{ fontSize: '1.5rem' }} />
								</Button>
							</div>
						)}
					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center', gap: '1.5vw' }}>
						{showWaiter && (
							<IconButton
								style={{ color: '#fff' }}
								className="appBarIcons"
								onClick={() => {
									setOpenWaiter(true);
								}}>
								<LuHandPlatter style={{ fontSize: '28px' }} />
							</IconButton>
						)}

						{auth?.client?.config_cliente?.usa_multilinguagem && !authDelivery?.client ? (
							<CardapioTranslationOptions />
						) : (
							<IconButton className="appBarIcons" onClick={() => setOpenFilter(true)}>
								<TuneOutlinedIcon sx={{ fontSize: '28px' }} />
							</IconButton>
						)}
					</Box>
				</Box>

				<Box className="boxAppBarLayout">
					<Box sx={{ width: '100%', height: '10vh' }}>
						{auth?.client?.config_cliente.img_fundo ? <img className="backgroundImgBar" src={auth?.client?.config_cliente.img_fundo}></img> : <Box className="backgroundImgBar" sx={{ background: '#' + auth?.client?.config_cliente.cor }}></Box>}
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
						<Box className="boxBackground">
							{auth?.client?.config_cliente?.img_logo && (
								<Box className="boxBackgroundLogo">
									<img
										className="logoAvaliacaoBar"
										src={auth?.client?.config_cliente?.img_logo}
										alt="Logo"
										style={{
											position: 'absolute',
											top: '-4vh',
										}}
									/>
								</Box>
							)}
						</Box>
					</Box>

					<Box sx={{ marginBottom: authDelivery?.client ? '1vh' : '2vh' }}>
						<Typography className="typoNomeCliente" sx={{ fontFamily: p600, marginTop: auth?.client?.config_cliente?.img_logo ? '5vh ' : '3vh' }}>
							{auth?.client?.config_cliente?.titulo_mobile}
						</Typography>

						{auth?.client?.config_cliente?.valor_minimo_delivery !== '0.00' && authDelivery?.client && (
							<Typography className="infoRestaurante" sx={{ fontFamily: p400 }}>
								Pedido minino R$ {auth?.client?.config_cliente?.valor_minimo_delivery.replace('.', ',')}
							</Typography>
						)}
					</Box>

					{auth?.client?.config_cliente?.tempo_entrega && (
						<Box className="boxDeliveryTime">
							<Typography className="deliveryTime" sx={{ fontFamily: p400 }}>
								{auth?.client?.config_cliente?.tempo_entrega}
							</Typography>
						</Box>
					)}
				</Box>
			</Box>

			{client?.config_cliente?.avaliacoes?.modulos && <IconRatingComponent />}
			<ModalWaiter open={openWaiter} handleClose={handleCloseWaiter} />
			<ModalInfo open={showSnack} handler={handleModal} message={snackMsg} />
			<ModalFilter open={openFilter} setOpen={setOpenFilter} />
			{isPedidos && <FixedDepartmentsDelivery currentValue={value} scrollDown={scrollDown} scrollPosition={scrollPosition} />}
			<RatingScreenCardapio open={ratingScreenOpen} setOpen={setRatingScreenOpen} isPage={true} />
		</Box>
	);
}
