import { useAppSelector, useAppDispatch } from '@/redux/hooks';
import { Box, Button } from '@mui/material';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { setOpenFidelidade } from '@/redux/reducers/cardapioFidelity/cardapioAuthFidelity';

type Props = {};

export default function Header({}: Props) {
	const dispatch = useAppDispatch();
	const { client } = useAppSelector(state => state.authDelivery);

	return (
		<Box>
			<Box sx={{ position: 'absolute', top: '1.5vh' }}>
				<div className="headerBottomSheet">
					<Button variant="contained" className="btn" onClick={() => dispatch(setOpenFidelidade(false))}>
						<KeyboardArrowDownIcon />
					</Button>
				</div>
			</Box>
			<Box
				className="banner"
				style={{
					backgroundImage: `url(${client?.config_cliente.img_fundo})`,
				}}></Box>
			<Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
				<Box className="boxBackgroundFidelidade">
					{client?.config_cliente?.img_logo && (
						<Box className="boxBackgroundLogo">
							<img
								className="logoAvaliacaoBar"
								src={client?.config_cliente?.img_logo}
								alt="Logo"
								style={{
									position: 'absolute',
									top: '-4vh',
								}}
							/>
						</Box>
					)}
				</Box>
			</Box>
			{/* <Box className="header">
				<h1 className="clientName">{client?.config_cliente.fidelidade_titulo}</h1>
				<p className="clientLocation">{client?.config_cliente.fidelidade_subtitulo}</p>
			</Box> */}
		</Box>
	);
}
